import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { SessionService } from 'src/app/service/session.service';
import { MessageService } from 'primeng/api';
import { LoadingService } from './shared/loading/loading.service.component';
// const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'authorization';    // for Node.js Express back-end
@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private sessionService: SessionService, private message: MessageService, private loading: LoadingService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = localStorage.getItem("token");
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return (next.handle(authReq) as any).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('v1/loginUserWeb') && error.status === 401) {
        HttpsRequestInterceptor.bind(this).prototype.message.add({ severity: 'error', summary: 'Erro ao fazer login', detail: 'Usuário ou senha incorretos' });
        HttpsRequestInterceptor.bind(this).prototype.loading.hideLoading();
      }
      return throwError(error);
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        return this.sessionService.refreshToken(refreshToken).pipe(
          switchMap((result: any) => {
            this.isRefreshing = false;
            localStorage.setItem("token", result.DATA.access_token);
            localStorage.setItem("refreshToken", result.DATA.refresh_token);
            this.refreshTokenSubject.next(result.DATA.access_token);
            console.log("TOKEN REFRESHED .........")
            return next.handle(this.addTokenHeader(request, result.DATA.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.sessionService.logout();
            return throwError(err);
          })
        );
      }
      else {
          this.isRefreshing = false;
          this.sessionService.logout();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    /* for Node.js Express back-end */
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }
}
@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpsRequestInterceptor,
        multi: true,
    }]
})
export class Interceptor { }
