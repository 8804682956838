<!-- <div class="searchField py-3">
  <div class="position-relative">
    <p-columnFilter type="text" [showMenu]="false" placeholder="Pesquisar" field="search"></p-columnFilter>
    <i style="top: 30%; left: 10px" class="pi pi-search position-absolute left-0"></i>
  </div>
</div> -->


<div class="table-component">
  <p-table *ngIf="tableData && tableData.length > 0" [lazy]="false" [value]="tableData" (onLazyLoad)="loadData($event)"
    responsiveLayout="scroll" dataKey="id" [selection]="selectedCustomers" (selectionChange)="onSelectionChange($event)"
    [paginator]="item?.config.paginator === false ? false : true" [rows]="10" [totalRecords]="totalRecords"
    [loading]="loading" [globalFilterFields]="tableColumns">
    <ng-template pTemplate="header">

      <tr *ngIf="item?.colunas">
        <ng-container *ngFor="let value of tableColumnField">
          <th *ngIf="!findIdString(value.column)" class="tableHeader" [class.statusColunm]="value.column == 'status'">
            {{value.column}}
            <p-sortIcon *ngIf="!item?.config?.disableSort" field="{{value.field}}">
            </p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <ng-container *ngFor="let key of dataKeys; let i = index">
          <td class="cursor-pointer" (click)="key != 'status' ? selectItem(data) : null"
            *ngIf="key != 'fotoUrl' && key != 'status' && key != 'id' && !findIdString(key) && key != 'createdAt'">
            {{data[key]}}</td>

          <td *ngIf="key == 'status'" style="min-width: 20px;">
            <div *ngIf="item?.type == 1" class="d-flex align-items-center justify-content-between">
              <span class="active-label">{{data.status == 1 ? 'Ativo' : 'Inativo'}}</span>
              <button
                (click)="data.status == 1 ? data.status = 0 : data.status = 1; selectItem({value:{id: data.id, data: data.status}})"
                class="btn btn-danger my-2 fw-bold" [ngClass]="{'inativo' : data.status !== 1}">{{data.status == 1 ?
                'Desativar' :
                'Ativar'}}</button>
              <!-- <i class="pi pi-chevron-circle-right"></i> -->
            </div>
          </td>
          <td class="cursor-pointer" *ngIf="key == 'createdAt'">{{data[key] | date: 'dd/MM/yyyy'}}</td>
          <td class="cursor-pointer" *ngIf="key == 'fotoUrl'">
            <p-image [src]="data?.fotoUrl" alt="Image" width="50" height="50"
              [preview]="true"></p-image>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
