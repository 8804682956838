<div class="filter-div p-3 pb-0 px-4  d-flex mb-4">
  <div class="me-3">
    <b class="me-3 titleCalendar">De</b>
    <input type="date" [(ngModel)]="dataInicio">
  </div>
  <div class="me-3">
    <b class="me-3 titleCalendar">Até</b>
    <input type="date" [(ngModel)]="dataFim">

  </div>
  <!-- Filtrar -->
  <button pButton pRipple type="button" (click)="ngOnInit()" label="OK" class="p-button-success filter-button"></button>

  <!-- Adicionar Usuário -->
  <!-- <p-button label="Exportar (.csv)" class="add-button ms-auto"></p-button> -->
</div>

<div class="px-4 py-5 ">

  <div class="mb-4">
    <div class="grid">
        <div class="col-12 lg:col-4 p-3">
            <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                <div>
                    <span class="text-900 text-xl font-medium mb-2">Total de Vagas Operacionais</span>
                    <p class="mt-1 mb-0 text-600 font-medium text-sm">{{estacionamentoHistorico?.totalVagas}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-4 p-3">
            <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                <div>
                    <span class="text-900 text-xl font-medium mb-2">Carros</span>
                    <p class="mt-1 mb-0 text-600 font-medium text-sm">{{0}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-4 p-3">
            <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                <div>
                    <span class="text-900 text-xl font-medium mb-2">Motos</span>
                    <p class="mt-1 mb-0 text-600 font-medium text-sm">{{0}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
  <div class="grid justify-content-center text-center">
    <div class="md:col-12 lg:col-6 sm:col-12 col-div" style="text-align: -webkit-center;">
      <div class="left-title">
        <span>Receita Auferida no Período por Plataforma de Vendas</span>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox">
          <span>APP</span>
        </div>
        <div class="bluebox">
          <span>{{totalVendas?.app}}</span>
        </div>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox">
          <span>Monitores</span>
        </div>
        <div class="bluebox">
          <span>{{totalVendas?.monitores}}</span>
        </div>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox">
          <span>PDV's</span>
        </div>
        <div class="bluebox">
          <span>{{totalVendas?.pdv}}</span>
        </div>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox" style="background-color: #0586d1">
          <b>Receita Total</b>
        </div>
        <div class="bluebox" style="background-color: #0586d1">
          <b>{{totalVendas?.geral}}</b>
        </div>
      </div>
    </div>
    <div class="md:col-12 lg:col-6 sm:col-12 col-div" style="text-align: -webkit-center;">
      <div class="left-title" style="background-color: #ff761b;">
        <span>Métricas & Resultados</span>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>Taxa de Ocupação Média</span>
        </div>
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>{{estacionamentoHistorico?.indiceOcupacao}}%</span>
        </div>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>N° de Infrações Identificadas</span>
        </div>
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>{{totalItems}}</span>
        </div>
      </div>
      <div class="d-flex box-container">
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>N° Autuações Emitidas</span>
        </div>
        <div class="bluebox" style="background-color: #ffbd00;">
          <span>{{0}}</span>
        </div>
      </div>
    </div>
  </div>
</div>