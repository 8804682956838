<section class="position-relative">
  <div class="filter-div p-3 pb-0 px-4  d-flex mb-4">
    <div class="me-3">
      <b class="me-3 titleCalendar">De</b>
      <input type="date" [(ngModel)]="dataInicio">
    </div>
    <div class="me-3">
      <b class="me-3 titleCalendar">Até</b>
      <input type="date" [(ngModel)]="dataFim">
    </div>

    <div class="me-3">
      <b class="me-3 titleFilter">Placa</b>
      <span class="p-input-icon-right mt-5 mb-2 md:mt-0 md:mb-0 w-full lg:w-10rem">
        <input type="text" [(ngModel)]="searchText"
          class="p-inputtext w-full  surface-border" placeholder="Digite" />
      </span>
    </div>

    <div class="me-3">
      <button pButton pRipple label="Filtrar" icon="pi pi-filter"
        class="block font-bold white-space-nowrap p-button-outlined p-button-filter" (click)="ngOnInit()"></button>

    </div>
    <!-- Exportar -->
    <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto"></p-button>
  </div>

  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">


    <div class="grid">
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span (click)="refresh()" class="block text-title mb-3">Total Vagas</span>
            </div>
          </div>
          <span class="text-footer">{{estacionamentoHistorico?.totalVagas}}</span>
        </div>
      </div>
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Horas Disponibilizadas</span>
            </div>

          </div>
          <span class="text-footer">{{estacionamentoHistorico?.horasDisponibilizadas}}</span>
        </div>
      </div>
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Ocupação (horas)</span>
            </div>

          </div>
          <span class="text-footer">{{estacionamentoHistorico?.ocupacao}}</span>
        </div>
      </div>
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Índice de Ocupação</span>
            </div>

          </div>
          <span class="text-footer">{{estacionamentoHistorico?.indiceOcupacao}} %</span>
        </div>
      </div>
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Qtde Estacionamentos</span>
            </div>

          </div>
          <span class="text-footer">{{estacionamentoHistorico?.qtdeEstacionamentos}}</span>
        </div>
      </div>
      <div class="md:col-6 lg:col-3 sm:col-12">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Tempo Médio de Ocupação</span>
            </div>

          </div>
          <span class="text-footer">{{estacionamentoHistorico?.tempoMedioOcupacao}}</span>
        </div>
      </div>
    </div>

    <app-generic-table *ngIf="estacionamentoData.length > 0 && showComponent; else empty"
      [item]="{colunas: colunas, data: estacionamentoData, type: 1, config: {disableSort: true, fields: fields, paginator: false}}"
      class="p-5 pb-0"></app-generic-table>
    <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
      [rowsPerPageOptions]="[perPage]"></p-paginator>
  </div>
</section>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>
