import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient, private router: Router) { }

  getUsers() {
    const routerState: any = this.router.routerState.snapshot.url;
    console.log("🚀 ~ file: user.service.ts:15 ~ UserService ~ getUsers ~ routerState", routerState);
    let url = '';
    switch (routerState) {
      case '/sistema/usuarios/sistema':
        url = '/v1/admin/usuarios-sistema';
        break;
      case '/sistema/usuarios/monitores':
        url = '/v1/admin/operadores-pos';
        break;
      case '/sistema/usuarios/fisc':
        url = '/v1/admin/agentes-fiscalizacao';
        break;
      case '/sistema/usuarios/venda':
        url = '/v1/admin/canais-fisico-venda';
        break;
      case '/sistema/usuarios/clientes':
        url = '/v1/admin/clientes';
        break;
      default:
        url = '/v1/admin/usuarios-sistema';
        break;
    }
    const accessToken = localStorage.getItem('token');
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            Authorization: `Bearer ${accessToken}`
          })
        };
    let link = `${this.base_url}${url}`
    if(localStorage.getItem('idCidade') && (this.router.routerState.snapshot.url !== '/sistema/usuarios/clientes') && localStorage.getItem('tipoUsuario') !== '5'){
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if(localStorage.getItem('tipoUsuario') === '5'){
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  putUser(id: string, data: any) {
    const accessToken = localStorage.getItem('token');
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            Authorization: `Bearer ${accessToken}`
          })
        };
    if(!this.isNumber(data)){
      data.senha = undefined;
      data.confirmPassword = undefined;
    }

    return new Promise(async (resolve, reject) => {
      this.http
        .put(`${this.base_url}/v1/usuario/${id}`, data, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  isNumber(value: any): boolean {
    return typeof value === 'number' && !isNaN(value);
  }
}

