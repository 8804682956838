import { Component, OnInit } from '@angular/core';
import { EstacionamentoService } from 'src/app/service/estacionamento.service';
import { MultasService } from 'src/app/service/multas.service';
import { RelatorioFinanceiroService } from 'src/app/service/relatorio-financeiro.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  estacionamentoHistorico: any;
  multasData: never[];
  totalItems: any;
  constructor(private Relatorioservice: RelatorioFinanceiroService, private loading: LoadingService, private readonly estacionamentoService: EstacionamentoService, private readonly multaService: MultasService) { }
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  relatorioData: any;
  meiosPagamento: any;
  totalVendas: any;

  intl = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  ngOnInit() {
    this.getRelatorioFinaceiro();
    this.getHistoricoEstacionamento();
    this.getMultas();
  }

  getRelatorioFinaceiro() {
    this.loading.showLoading();
    this.Relatorioservice.getRelatorioFinanceiro(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString()).subscribe({
      next: (result) => {
        this.relatorioData = result.DATA;
        this.meiosPagamento = result.DATA.meiosPagamento;
        this.totalVendas = result.DATA.totalVendas;
        this.totalVendas.app = this.intl.format(this.totalVendas.app);
        this.totalVendas.monitores = this.intl.format(this.totalVendas.monitores);
        this.totalVendas.pdv = this.intl.format(this.totalVendas.pdv);
        this.totalVendas.geral = this.intl.format(this.totalVendas.geral);
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();

      },
      complete: () => {
        console.log("ok");
        this.loading.hideLoading();
      }
    })
  }

  getMultas() {
    this.loading.showLoading();
    this.multasData = [];
    this.multaService.getMultas(undefined, undefined, 1, 9999, this.dataInicio, this.dataFim).then((res: any) => {
      console.log("🚀 ~ file: consulta-de-multas.component.ts:55 ~ ConsultaDeMultasComponent ~ this.multaService.getMultas ~ res:", res)
      this.multasData = res.data;
      this.totalItems = res.total;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      console.log("🚀 ~ file: dashboard.component.ts:68 ~ DashboardComponent ~ getMultas ~ err:", err)
      this.loading.hideLoading();
    });
  }

  getHistoricoEstacionamento() {
    this.loading.showLoading();
    this.estacionamentoService.getEstacionamentosQuadros(new Date(this.dataInicio).toDateString(), new Date(this.dataFim).toDateString()).subscribe({
      next: (result) => {
        this.estacionamentoHistorico = result.DATA.data
      },
      error: (error) => {
        this.loading.hideLoading();
        console.log(error)
      },
      complete: () => {
      }
    })
  }
}
