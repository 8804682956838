import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatorioFinanceiroService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getRelatorioFinanceiro(dateBegin?: string, dateEnd?: string):Observable<any>{
    const accessToken = localStorage.getItem('token');
    const idCidade = localStorage.getItem('idCidade');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.http.get<any>(`${this.base_url}/v1/admin/relatorio-financeiro?cidade=${idCidade}&inicio=${dateBegin}&final=${dateEnd}`, httpOptions).pipe(take(1))
  }
}
