<section class="position-relative">
    <!-- Type 1: Status, Type 0: Sem status -->
    <div class="table-div">
        <div class="filter-div p-3 pb-0 px-1 d-flex mb-4">
            <div class="me-3">
                <b class="me-1 titleCalendar">De</b>
                <input type="date" [(ngModel)]="dataInicio">
            </div>
            <div class="me-2">
                <b class="me-3 titleCalendar">Até</b>
                <input type="date" [(ngModel)]="dataFim">
            </div>

            <div class="me-3 mt-4">
                <button pButton pRipple label="Filtrar" (click)="getRotas()" icon="pi pi-filter"
                    class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>
            </div>

            <div class="me-3">
                <b class="me-1 titleFilter">Monitor</b>
                <p-dropdown [options]="agentes" placeholder="Todos" [showClear]="true" [required]="false"
                    [(ngModel)]="selectedAgentes" optionLabel="nome" optionValue="id"></p-dropdown>

            </div>
        </div>
        <div class="grid">
            <div class="md:col-6 lg:col-3 sm:col-12">
                <div class="surface-card shadow-2 p-3 border-round" style="width: fit-content;">
                    <span class="text-footer"> <b>Total: </b>{{valorTotal}}</span>
                </div>
            </div>
        </div>
        <!-- <app-generic-table *ngIf="creditosData.length > 0" -->
        <app-generic-table *ngIf="rotas && rotas.length > 0 && showComponent; else empty"
            [item]="{colunas: colunas, data: rotas, type: 1, config: {disableSort: true, fields: fields, paginator: false}}"
            class="p-5 pb-0"></app-generic-table>

        <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
            [rowsPerPageOptions]="[perPage]"></p-paginator>
    </div>
</section>

<ng-template #empty>
    <div class="empty-table">
        <p class="text-center">Nenhum registro encontrado</p>
    </div>
</ng-template>