<nav class="navbar navbar-light justify-content-between px-4 mx-5 mt-3">
    <a class="navbar-brand">{{ this.nameScreen }}</a>
    <div class="flex-shrink-0 btn-lg-square rounded-circle gap-3 d-flex">
        <span style="color: #6dafff">Versão 1.4.0</span>
        <div *ngIf="userType == '0'" pTooltip="Bem-vindo! Você está logado como MASTER ADMIN." tooltipPosition="bottom">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-person-circle cursor-pointer"
                viewBox="0 0 16 16" fill="#53EE5A">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
            </svg>
        </div>
    </div>
</nav>