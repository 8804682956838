<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <span class="text-700 text-xl">Obrigado!</span>
    <div class="text-900 font-bold text-4xl my-2">Compra Realizada 🚀</div>
    <p class="text-700 text-xl mt-0 mb-4 p-0">Seus créditos foram adicionados. Eles estão disponíveis no app SmartParking.</p>
    <div style="height:3px;background:linear-gradient(90deg, #2196F3 0%, rgba(33, 150, 243, 0) 50%);"></div>

    <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between py-5">
        <div class="mb-3 sm:mb-0">
            <span class="font-medium text-xl text-900 mr-2">Id da compra:</span>
            <span class="font-medium text-xl text-blue-500">{{data?.id}}</span>
        </div>
        <div class="mb-3 sm:mb-0">
            <span class="font-medium text-xl text-900 mr-2 block">Smartparking Estacionamento Inteligente Ltda</span>
            <span class="font-medium text-xl text-900 mr-2">CNPJ:</span>
            <span class="font-medium text-xl text-blue-500">48.176.676/0001-67</span>
        </div>
        <!-- <div>
            <button pButton pRipple label="Detalhes" icon="pi pi-list" class="p-button-outlined p-button-secondary mr-2"></button>
            <button pButton pRipple label="Imprimir" icon="pi pi-print" class="p-button-outlined p-button-secondary"></button>
        </div> -->
    </div>
    <div class="border-round surface-border border-1">
        <ul class="list-none p-0 m-0">
            <li class="p-3 border-bottom-1 surface-border flex align-items-start sm:align-items-center">
                <div class="flex flex-column">
                    <span class="text-900 font-medium text-xl mb-2" style="font-size: 1.30rem !important;">Créditos para Estacionamento</span>
                    <span class="text-600 mb-3">Válido para {{data?.cidade.nome}}</span>
                </div>
                <span class="text-900 font-medium text-lg ml-auto">{{intl.format(data?.valor)}}</span>
            </li>
        </ul>
    </div>
    <div class="flex flex-wrap mt-5 pb-3">
        <div class="w-full lg:w-6 pl-3">
            <div *ngIf="data?.tipoUserCreator === 3">
                <span class="font-medium text-900">Endereço de Cobrança</span>
                <div class="flex flex-column text-900 mt-3 mb-5">
                    <span class="mb-1">{{data?.usuario?.nome}}</span>
                    <span class="mb-1">
                        {{data?.usuario?.endereco?.rua}}, {{data?.usuario?.endereco?.numero}}. {{data?.usuario?.endereco?.cidade}} {{data?.usuario?.endereco?.estado}} {{data?.usuario?.endereco?.cep}}
                    </span>
                    <span>{{formatCellphone(data?.usuario?.celular)}}</span>
                </div>
            </div>

            <div *ngIf="data?.tipoUserCreator !== 3">
                <span class="font-medium text-900">Endereço de Cobrança</span>
                <div class="flex flex-column text-900 mt-3 mb-5">
                    <span class="mb-1 fw-bold">Feito Por</span>
                    <span class="mb-1">{{data?.feitoPor}}</span>
                </div>
            </div>

            

            <span class="font-medium text-900">Pagamento</span>
            <div class="flex align-items-center mt-3">
                <div class="flex flex-column">
                    <span class="text-900 mb-1 fw-bold">{{data?.pagamento}}</span>
                </div>
            </div>
        </div>
        <div class="w-full lg:w-6 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
            <ul class="list-none p-0 m-0 w-full">
                <li class="mb-3"><span class="font-medium text-900">Resumo</span></li>
                <li class="flex justify-content-between mb-3">
                    <span class="text-900">Subtotal</span>
                    <span class="text-900 font-medium text-lg">{{intl.format(data?.valor)}}</span>
                </li>
                <li class="flex justify-content-between border-top-1 surface-border py-3">
                    <span class="text-900 font-medium">Total</span>
                    <span class="text-900 font-bold text-lg">{{intl.format(data?.valor)}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
