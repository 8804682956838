<div class="min-h-screen flex surface-ground">
  <div id="app-sidebar-2"
    class="bg-sb h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 select-none"
    style="width:280px">
    <div class="flex flex-column h-full">
      <div class="flex flex-column align-items-center align-self-center pt-5 flex-shrink-0 w-full">
        <img class="cursor-pointer" src="assets/images/logo_menu.svg" alt="Image" height="30">
        <div class="list-city w-full">
          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors" pStyleClass="@next" enterClass="hidden"
              enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
              <span class=" titleSidebar">{{City}}</span>
              <i class="pi pi-chevron-down ml-auto" *ngIf="tipouser"></i>
            </a>
            <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out" *ngIf="tipouser" >
              <li (click)="setCidade(item.id)" *ngFor="let item of allCities">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas">{{item.city}}</span>
                </a>
              </li>
            </ul>
          </li>
          <hr class="mx-3">
        </div>
        <!-- <h1 class="color-white">LOGO</h1> -->
      </div>

      <div class="overflow-y-auto mt-1">
        <ul class="list-none p-3 m-0">
          <li *ngIf="userTipo == '0'" routerLinkActive="active" routerLink="operacoes">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class=" titleSidebar label-telas" routerLinkActive="active">Operações</span>
            </a>
          </li>

          <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="veiculos-autorizados">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class=" titleSidebar label-telas" routerLinkActive="active">Veículos Autorizados</span>
            </a>
          </li>
          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors" pStyleClass="@next" enterClass="hidden"
              enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
              <span class=" titleSidebar">Usuários</span>
              <i class="pi pi-chevron-down ml-auto"></i>
            </a>
            <ul
              class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
              <li *ngIf="userTipo == '0'" routerLinkActive="active" routerLink="usuarios/sistema">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Usuários do Sistema</span>
                </a>
              </li>
              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/monitores">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Monitores</span>
                </a>
              </li>
              <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '6'" routerLinkActive="active" routerLink="usuarios/fisc">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Agentes de Fiscalização</span>
                </a>
              </li>
              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/venda">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Ponto de Vendas</span>
                </a>
              </li>
              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/clientes">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Clientes</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="userTipo == '0'" routerLinkActive="active" routerLink="dashboard">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class="titleSidebar" routerLinkActive="active">Dashboard</span>
            </a>
          </li>

          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors" pStyleClass="@next" enterClass="hidden"
              enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
              <span class=" titleSidebar">Históricos</span>
              <i class="pi pi-chevron-down ml-auto"></i>
            </a>
            <ul
              class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="historicos/vendas">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Vendas</span>
                </a>
              </li>

              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="historicos/estacionamentos">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Estacionamentos</span>
                </a>
              </li>

              <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '6'" routerLinkActive="active" routerLink="historicos/fiscalizacao">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                            transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Fiscalização</span>
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="userTipo == '0' || userTipo == '5'">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors" pStyleClass="@next" enterClass="hidden"
              enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
              <span class=" titleSidebar">Consultas</span>
              <i class="pi pi-chevron-down ml-auto"></i>
            </a>
            <ul
              class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">

              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="consultas/creditos">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Créditos</span>
                </a>
              </li>

              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="consultas/infracoes/1/10">
                <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class="subtitleSidebar label-telas" routerLinkActive="active">Infrações</span>
                </a>
              </li>

              <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="consultas/avaliacao-monitores">
                <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-blue-600 hover:text-bluegray-50
                              transition-duration-150 transition-colors">
                  <span class=" subtitleSidebar label-telas" routerLinkActive="active">Avaliação de Monitores</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="fechamento/monitores">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class="titleSidebar label-telas" routerLinkActive="active">Fechamento Monitores</span>
            </a>
          </li>

          <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="fechamento/pdv">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class="titleSidebar label-telas" routerLinkActive="active">Fechamento PDV</span>
            </a>
          </li>

          <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '6'" routerLinkActive="active" routerLink="relatorio-financeiro">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class=" titleSidebar label-telas" routerLinkActive="active">Relatório Financeiro</span>
            </a>
          </li>
          <hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600" />
          <li (click)="logout()">
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                          transition-duration-150 transition-colors">
              <span class=" titleSidebar">Sair</span>
            </a>
        </ul>
      </div>
      <div class="mt-auto">
        <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                      transition-duration-150 transition-colors">
              <i class="pi pi-user mr-2"></i>
              <span class=" subtitleSidebar label-telas" routerLink="active">Profile</span>
            </a>
          </li>
          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                      transition-duration-150 transition-colors">
              <i class="pi pi-cog mr-2"></i>
              <span class=" subtitleSidebar label-telas" routerLink="active">Settings</span>
            </a>
          </li>
          <li>
            <a pRipple class="flex align-items-center cursor-pointer p-3  border-round text-bluegray-100 hover:text-bluegray-50
                      transition-duration-150 transition-colors">
              <i class="pi pi-sign-out mr-2"></i>
              <span class="titleSidebar">Sign Out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="min-h-screen flex flex-column relative flex-auto">
    <app-navbar></app-navbar>
    <div class="p-5 flex flex-column flex-auto">
      <div class="surface-border border-round surface-section flex-auto p-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
