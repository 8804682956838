<section class="table-div p-4 h-100">
  <div class="filter-div p-3 pb-0 d-flex mb-4">
    <div class="me-3">
      <b class="me-3">OperadorPOS</b>
      <p-dropdown class="border-10" [options]="agentList"
        [(ngModel)]="agente" placeholder="Todos" optionLabel="Nome" optionValue="id"></p-dropdown>
    </div>
    <div class="me-3">
      <b class="me-3">De</b>
      <p-calendar icon="pi pi-calendar" dateFormat="dd/mm/yy" [showIcon]="true" [(ngModel)]="data"></p-calendar>
    </div>
    <p-button (click)="getLog()" label="OK" class="add-button"></p-button>
    <!-- Adicionar Usuário -->
    <p-button label="Exportar (.csv)" (click)="export()" class="add-button ms-auto"></p-button>
  </div>

  <div class="d-flex">
    <div>
      <b class="d-block">Forma de Pagamento</b>
      <hr>
      <span class="d-block">Cartão de Débito</span>
      <hr>
      <span class="d-block">Cartão de Crédito</span>
      <hr>
      <span class="d-block">Dinheiro</span>
      <hr>
      <span class="d-block">Pix</span>
    </div>
    <div>
      <b class="d-block ms-5">Valor</b>
      <hr>
      <span class="d-block ms-5">{{value.debito | currency:'BRL'}}</span>
      <hr>
      <span class="d-block ms-5">{{value.credito | currency:'BRL'}}</span>
      <hr>
      <span class="d-block ms-5">{{value.dinheiro | currency:'BRL'}}</span>
      <hr>
      <span class="d-block ms-5">{{value.pix | currency:'BRL'}}</span>
    </div>
  </div>
</section>
