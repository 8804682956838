import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreditoService } from 'src/app/service/credito.service';

@Component({
  selector: 'app-recibo',
  templateUrl: './recibo.component.html',
  styleUrls: ['./recibo.component.css']
})
export class ReciboComponent implements OnInit {
  data: any;
  id: any;
  intl = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  constructor(private creditoService: CreditoService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCredito();
  }

  getCredito() {
    this.creditoService.getCreditoById(this.id).then((result: any) => {
      this.data = result;
      console.log(this.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  formatCellphone(cellphone: string): string | null {
    const cleaned = ('' + cellphone).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + ' ' + match[3] + '-' + match[4];
    }
    return null;
  }

}
