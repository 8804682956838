<section class="position-relative">
  <div class="page-title">
    <!-- <h5 class="fw-bold">Usuários</h5> -->
  </div>
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">

    <div class="filter-div p-3 pb-0 d-flex">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" [(ngModel)]="searchText" (ngModelChange)="filterItems(searchText)"
            placeholder="Pesquisar" field="search" />
        </span>
      </div>

      <p-button *ngIf="router.routerState.snapshot.url !== '/sistema/usuarios/clientes'" (onClick)="newUserDiag()"
        label="Adicionar Usuário" icon="pi pi-plus-circle" class="add-button ms-auto p-button-success"
        iconPos="left"></p-button>
    </div>
    <app-generic-table (selectedData)="updateUser($event)" *ngIf="userData.length > 0 && showComponent; else empty"
      [item]="{colunas: colunas, data: searchText && searchText.length > 0 ? filteredItems : userData, type: 1, config: {disableSort: true, columnIndex: true, fields: fields}}"
      class="p-5 pb-0"></app-generic-table>
  </div>
</section>

<!-- Modal adicionar {{edit ? 'Editar usuário' : 'Novo usuário'}} - Usuários do Sistema -->

<p-dialog *ngIf="addUserType === 0" header="{{edit ? 'Editar usuário' : 'Novo usuário'}}" styleClass="modalUserForm"
  [(visible)]="addUserDiag" [modal]="true" [draggable]="false" [resizable]="false">
  <form class="addUserForm" action="" [formGroup]="postForm">
    <div class="spacer"></div>
    <div class="row">
      <div class="col">
        <label class="form-label d-block">Nome</label>
        <input placeholder="Nome" class="form-control" formControlName="nome" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">Código do usuário (login)</label>
        <input placeholder="Código do Usuário" class="form-control" formControlName="username" type="text" pInputText />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label d-block">Email</label>
        <input placeholder="E-mail" class="form-control" formControlName="email" type="email" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">Celular</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="(99) 99999-9999"
          [autoClear]="false" formControlName="celular" placeholder="(99) 99999-9999"></p-inputMask>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="form-label d-block">Perfil</label>
        <p-dropdown appendTo="body" [options]="[{label: 'Admin Local', tipo: 5}, {label: 'Prefeitura', tipo: 6}]"
          formControlName="tipo" autoWidth="false" [style]="{'width':'100%'}" optionLabel="label"
          optionValue="tipo"></p-dropdown>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Cadastrar Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Senha" class="form-control"
          formControlName="senha" type="password"></p-password>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Repetir Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Repetir Senha" class="form-control"
          formControlName="confirmPassword" type="password" pInputText></p-password>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button (click)="addUserDiag = false" pButton pRipple type="button" label="Cancelar" icon="pi pi-times"
      class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="newUser()" class="p-button-save"
      [disabled]="postForm.invalid"></button>

    <!-- <p-button (onClick)="newUser()" [disabled]="addUserForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button> -->
  </ng-template>

</p-dialog>

<!-- Modal adicionar {{edit ? 'Editar usuário' : 'Novo usuário'}} - Operadores de POS -->

<p-dialog *ngIf="addUserType === 1" header="{{edit ? 'Editar usuário' : 'Novo usuário'}}" styleClass="modalUserForm"
  [(visible)]="addUserDiag" [modal]="true" [draggable]="false" [resizable]="false">
  <form class="addUserForm" action="" [formGroup]="postForm">
    <div class="spacer"></div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label d-block">Código do usuário (login)</label>
        <input placeholder="Código do usuário" class="form-control" formControlName="username" type="text" pInputText />
      </div>
      <div class="col-md-6">
        <label class="form-label d-block">Código da Máquina</label>
        <input placeholder="Código da Máquina" class="form-control" formControlName="codigoPOS" type="text"
          pInputText />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label d-block">Nome do Usuario</label>
        <input placeholder="Nome do Usuario" class="form-control" formControlName="nome" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">E-mail</label>
        <input placeholder="E-mail" class="form-control" formControlName="email" type="email" pInputText />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="form-label d-block">Celular</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="(99) 99999-9999"
          [autoClear]="false" formControlName="celular" placeholder="(99) 99999-9999"></p-inputMask>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Cadastrar Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Senha" class="form-control" formControlName="senha" type="password"></p-password>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Repetir Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Repetir Senha" class="form-control" formControlName="confirmPassword" type="password"></p-password>
      </div>
      <div class="col-md-3">
        <label class="form-label d-block">Rota</label>
        <p-dropdown appendTo="body" [options]="rotas" placeholder="Selecione..." formControlName="rota"
          autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
      </div>
      <div class="col-md-3">
        <label class="form-label d-block">Vagas Carro</label>
        <p-inputNumber placeholder="00" class="" [min]="0" formControlName="vagaCarro" type="number" />
      </div>
      <div class="col-md-3">
        <label class="form-label d-block">Vagas Moto</label>
        <p-inputNumber placeholder="00" class="" [min]="0" formControlName="vagaMoto" type="number" />
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="button" (click)="addUserDiag = false" label="Cancelar" icon="pi pi-times"
      class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="newUser()" class="p-button-save"
      [disabled]="postForm.invalid"></button>

    <!-- <p-button (onClick)="newUser()" [disabled]="addUserForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button> -->
  </ng-template>
</p-dialog>

<!-- Modal adicionar {{edit ? 'Editar usuário' : 'Novo usuário'}} - Agentes de fiscalização -->

<p-dialog *ngIf="addUserType === 2" header="{{edit ? 'Editar usuário' : 'Novo usuário'}}" styleClass="modalUserForm"
  [(visible)]="addUserDiag" [modal]="true" [draggable]="false" [resizable]="false">
  <form class="addUserForm" action="" [formGroup]="postForm">
    <div class="spacer"></div>
    <div class="row">
      <div class="col-md-4">
        <label class="form-label d-block">Código do usuário (login)</label>
        <input placeholder="Código do usuário" class="form-control" formControlName="username" type="text" pInputText />
      </div>
      <div class="col-md-4">
        <label class="form-label d-block">Código na Prefeitura</label>
        <input placeholder="Código na Prefeitura" class="form-control" formControlName="codPrefeitura" type="text"
          pInputText />
      </div>
      <div class="col-md-4">
        <label class="form-label d-block">Nome do Usuário</label>
        <input placeholder="Nome do Usuário" class="form-control" formControlName="nome" type="text" pInputText />
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-lg-6">
        <label class="form-label d-block">E-mail</label>
        <input placeholder="E-mail" class="form-control" formControlName="email" type="email" pInputText />
      </div>
      <div class="col-lg-6">
        <label class="form-label d-block">Celular</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="(99) 99999-9999"
          [autoClear]="false" formControlName="celular" placeholder="(99) 99999-9999"></p-inputMask>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-6" *ngIf="!edit">
        <label class="form-label d-block">Cadastrar Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Senha" class="form-control" formControlName="senha" type="password"></p-password>
      </div>
      <div class="col-md-6" *ngIf="!edit">
        <label class="form-label d-block">Repetir Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Repetir Senha" class="form-control"
          formControlName="confirmPassword" type="password"></p-password>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="button" (click)="addUserDiag = false" label="Cancelar" icon="pi pi-times"
      class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="newUser()" class="p-button-save"
      [disabled]="postForm.invalid"></button>

    <!-- <p-button (onClick)="newUser()" [disabled]="addUserForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button> -->
  </ng-template>
</p-dialog>

<!-- Modal adicionar {{edit ? 'Editar usuário' : 'Novo usuário'}} - Canais Fisicos de Venda -->

<p-dialog *ngIf="addUserType === 3" header="{{edit ? 'Editar usuário' : 'Novo usuário'}}" styleClass="modalUserForm"
  [(visible)]="addUserDiag" [modal]="true" [draggable]="false" [resizable]="false">
  <form class="addUserForm" action="" [formGroup]="postForm">
    <div class="spacer"></div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label d-block">Código do Usuário (login)</label>
        <input placeholder="CÓDIGO" class="form-control" formControlName="username" type="text" pInputText />
      </div>
      <div class="col-md-6">
        <label class="form-label d-block">Código da Máquina</label>
        <input placeholder="CÓDIGO" class="form-control" formControlName="codMac" type="text" pInputText />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label d-block">Nome do Usuario</label>
        <input placeholder="Nome do Usuario" class="form-control" formControlName="nome" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">E-mail</label>
        <input placeholder="E-mail" class="form-control" formControlName="email" type="email" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">CNPJ</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="99.999.999/9999-99"
          [autoClear]="false" formControlName="documento" placeholder="99.999.999/9999-99"></p-inputMask>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="form-label d-block">Celular</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="(99) 99999-9999"
          [autoClear]="false" formControlName="celular" placeholder="(99) 99999-9999"></p-inputMask>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Cadastrar Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Senha" class="form-control"
          formControlName="senha" type="password"></p-password>
      </div>
      <div class="col-md-3" *ngIf="!edit">
        <label class="form-label d-block">Repetir Senha</label>
        <p-password [toggleMask]="true" [feedback]="false" placeholder="Repetir Senha" class="form-control"
          formControlName="confirmPassword" type="password"></p-password>
      </div>
    </div>
    <h6 class="mt-4">
      Endereço
    </h6>
    <div class="spacer"></div>
    <div class="row">
      <div class="col-md-3">
        <label class="form-label d-block">CEP</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="99999-999"
          [autoClear]="false" formControlName="cep" placeholder="99999-999"></p-inputMask>
      </div>
      <div class="col-md-9">
        <label class="form-label d-block">Logradouro</label>
        <input placeholder="Logradouro" class="form-control" formControlName="place" type="text" pInputText />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-3">
        <label class="form-label d-block">Número</label>
        <input placeholder="123" class="form-control" formControlName="num" type="number" pInputText />
      </div>
      <div class="col-md-6">
        <label class="form-label d-block">Complemento</label>
        <input placeholder="Complemento" class="form-control" formControlName="complement" type="text" pInputText />
      </div>
      <div class="col-md-3">
        <label class="form-label d-block">Bairro</label>
        <input placeholder="Bairro" class="form-control" formControlName="neighborhood" type="text" pInputText />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <label class="form-label d-block">Cidade</label>
        <input placeholder="Cidade" class="form-control" formControlName="city" type="text" pInputText />
      </div>
      <div class="col-md-6">
        <label class="form-label d-block">Estado</label>
        <input placeholder="Estado" class="form-control" formControlName="state" type="text" pInputText />
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="button" (click)="addUserDiag = false" label="Cancelar" icon="pi pi-times"
      class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="newUser()" class="p-button-save"
      [disabled]="postForm.invalid"></button>

    <!-- <p-button (onClick)="newUser()" [disabled]="addUserForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button> -->
  </ng-template>
</p-dialog>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>