import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConsultaPlacasService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getLog(filter: any) {
    const accessToken = localStorage.getItem('token');
    const query = `?placa=${filter.placa}&dataInicial=${filter.dataInicial}&dataFinal=${filter.dataFinal}&agente=${filter.agente}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/consultasPlaca/log`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA.data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  get(inicio: any, final: any, agente: string) {
    console.log("🚀 ~ file: consulta-placas.service.ts:35 ~ ConsultaPlacasService ~ get ~ agente:", agente)
    const accessToken = localStorage.getItem('token');
    let link = `${this.base_url}/v1/consultasPlaca`
    if(localStorage.getItem('idCidade')){
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if (inicio && final) {
      link += `&inicio=${inicio}&final=${final}`;
    }
    if (agente) {
      link += `&agente=${agente}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA.data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getAgentes(){
    let link = `${this.base_url}/v1/admin/dropdown-agentes-fiscalizacao`
    const accessToken = localStorage.getItem('token');
    if(localStorage.getItem('idCidade')){
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getPlacas(){
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/consultasPlaca/placas`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getRotas(query = {} as any){
    const idAgente = query['operadorPOS'];
    const inicio = query['inicio'];
    const final = query['final'];
    const page = query['page'] ?? 1;
    const perPage = query['perPage'] ?? 1000;

    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };

    let params = new HttpParams()
      .set('inicio', inicio)
      .set('final', final)
      .set('page', String(page))
      .set('perPage', String(perPage))
      .set('cidade', String(localStorage.getItem('idCidade')));
    if(idAgente){
      params = params.set('operadorPOS', idAgente);
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/admin/rotas`, { ...httpOptions, params: params }).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

}
