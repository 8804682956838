<section class="position-relative">
  <div class="filter-div p-3 pb-0 px-2 d-flex justify-content-between mb-4">
    <div class="col-lg-10 d-flex">
      <div class="me-1">
        <b class="me-2 titleCalendar">De</b>
        <input type="date" [(ngModel)]="dataInicio">
      </div>

      <div class="me-1">
        <b class="me-2 titleCalendar">Até</b>
        <input type="date" [(ngModel)]="dataFim">
      </div>

      <div class="me-3 d-flex align-items-center">
        <b class="me-2 titleFilter" style="white-space: nowrap;">Canal de Vendas</b>
        <p-dropdown *ngIf="typeFechamento === 0" [options]="dadosPOS" [(ngModel)]="selectedVendas"
          [optionLabel]="'nome'" optionValue="id"></p-dropdown>
        <p-dropdown *ngIf="typeFechamento === 1" [options]="dadosPDV" [(ngModel)]="selectedVendas"
          [optionLabel]="'nome'" optionValue="id"></p-dropdown>
      </div>

      <div class="me-3">
        <button pButton (click)="typeFechamento === 0 ? getCalculoPeriodoPos() : getCalculoPeriodoPdv()" pRipple label="Filtrar" icon="pi pi-filter"
          class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>

      </div>
    </div>

    <div class="col-lg-2">
      <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto"></p-button>
    </div>
  </div>


  <div class="table-div col-lg-10">
    <div class="table-title py-2 px-4">
      <a class="title-brand">Cálculo por Período</a>
    </div>

    <div class="grid mt-3" >
      <div class="lg:col-6">
        <div class="surface-section">
          <ul class="list-none p-0 m-0">
            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="title w-6 md:w-8 ">Forma de Pagamento</div>
              <div class="title w-full md:w-4 md:flex-order-0 flex-order-1" >Valor</div>
            </li>

            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="w-6 md:w-8 description">Cartão de Débito</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 0"> {{calculoPeriodoPOS?.debito | toReal}}</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 1"> {{calculoPeriodoPDV?.debito | toReal}}</div>

            </li>
            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="w-6 md:w-8 description">Cartão de Crédito</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 0"> {{calculoPeriodoPOS?.credito | toReal}}</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 1"> {{calculoPeriodoPDV?.credito | toReal}}</div>

            </li>
            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="w-6 md:w-8 description">Dinheiro</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3" *ngIf="typeFechamento === 0"> {{calculoPeriodoPOS?.dinheiro | toReal}}</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3" *ngIf="typeFechamento === 1"> {{calculoPeriodoPDV?.dinheiro | toReal}}</div>
            </li>
            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="w-6 md:w-8 description">Pix</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3" *ngIf="typeFechamento === 0">  {{calculoPeriodoPOS?.pix | toReal}}</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3" *ngIf="typeFechamento === 1">  {{calculoPeriodoPDV?.pix | toReal}}</div>
            </li>

            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="title w-6 md:w-8 ">Total</div>
              <div class="title w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 0"> {{calculoPeriodoPOS?.total | toReal}}</div>
              <div class="title w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 1"> {{calculoPeriodoPDV?.total | toReal}}</div>

            </li>
          </ul>
        </div>
      </div>

      <div class="lg:col-6">
        <div class="surface-section percent mx-5 py-6">
          <ul class="list-none p-0 m-0">
            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class=" w-6 md:w-8 description">Percentual</div>
              <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 d-flex align-items-center">
                <!-- <span class="ml-2" *ngIf="typeFechamento === 0">{{calculoPeriodoPOS?.percentual}}%</span> -->
                <!-- <input type="text" maxlength="7"  max="100.0" min="0.0" step="0.01" (input)="patternMask($event.target)" sufix="%" class="form-control" id="percentual" name="percentual" [(ngModel)]="calculoPeriodoPOS.percentual" *ngIf="typeFechamento === 0 && calculoPeriodoPOS"> -->
                <p-inputMask placeholder="00.00 %" mask="99.99 %" (onInput)="liquidValue(calculoPeriodoPOS?.total, calculoPeriodoPOS.percentual)" styleClass="form-control" id="percentual" name="percentual" [(ngModel)]="calculoPeriodoPOS.percentual" *ngIf="typeFechamento === 0 && calculoPeriodoPOS"></p-inputMask>
                <span class="ml-2" *ngIf="typeFechamento === 1">{{calculoPeriodoPDV?.percentual}}%</span>
              </div>

            </li>

            <li
              class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
              <div class="title w-6 md:w-8 ">Comissão</div>
              <div class="title w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 0">{{calculoPeriodoPOS?.valorLiquido | toReal}}</div>
              <div class="title w-full md:w-4 md:flex-order-0 flex-order-1" *ngIf="typeFechamento === 1">{{liquidValuePDV(calculoPeriodoPDV?.total, calculoPeriodoPDV.percentual) | toReal}}</div> 

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
