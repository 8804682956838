import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CidadeService } from 'src/app/service/cidade.service';
import { SessionService } from 'src/app/service/session.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { OperacoesService } from 'src/app/service/operacoes.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  status: boolean = false;
  tipouser: boolean = false;
  cityData: any[] = [];
  idCidade: string;
  dataCity: any[] = [];
  City: string;
  allCities: any;
  userTipo: any;

  constructor(
    private readonly session_service: SessionService,
    private readonly cityService: CidadeService,
    private messageService: MessageService,
    private loading: LoadingService
  ) { }

  async ngOnInit(): Promise<void> {
    const usertipo = localStorage.getItem('tipoUsuario');
    this.userTipo = usertipo;
    this.idCidade = String(localStorage.getItem('idCidade'));
    this.getCidade();
    if (usertipo === '0') {
      this.tipouser = true;
    }
  }

  logout() {
    this.session_service.logout();
  }

  getCidade() {
    this.loading.showLoading();
    this.cityService.getCidades().then(async (result: any) => {
      this.cityData = await result.filter((f: any) => f.id !== this.idCidade);;
      this.dataCity = await result.filter((f: any) => f.id === this.idCidade);
      this.allCities = result;
      console.log("🚀 ~ file: sidebar.component.ts:50 ~ SidebarComponent ~ this.cityService.getCidades ~ this.allCities:", this.allCities)
      console.log('this.cityData ==>', this.dataCity);
      if (this.dataCity.length > 0) {
        this.City = this.dataCity[0].city;
      }
      this.loading.hideLoading();
    }).catch(async (erro: any) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar cidades!', detail: erro });
      this.loading.hideLoading();
    });
  }

  setCidade(id: string) {
    this.idCidade = id;
    localStorage.setItem('idCidade', id);
    this.getCidade();
    location.reload();
  }

}
