<section class="table-div p-4 h-100">
  <div class="filter-div p-3 pb-0 d-flex mb-4">
    <div class="me-3">
      <b class="me-3">De</b>
      <p-calendar icon="pi pi-calendar" [showIcon]="true" [(ngModel)]="dataInicio"></p-calendar>
    </div>
    <div class="me-3">
      <b class="me-3">Até</b>
      <p-calendar icon="pi pi-calendar" [showIcon]="true" [(ngModel)]="dataFim"></p-calendar>
    </div>
    <div class="me-3">
      <b class="me-3">Agente</b>
      <p-dropdown class="border-10" [options]="agentList"
        [(ngModel)]="agente" placeholder="Todos" optionLabel="nome" optionValue="id"></p-dropdown>
    </div>
    <div class="me-3">
      <b class="me-3">Placa</b>
      <p-dropdown class="border-10" optionLabel="placa" optionValue="placa" placeholder="Todas" [options]="placaList"
        [(ngModel)]="placa"></p-dropdown>
    </div>
    <!-- Filtrar -->
    <p-button label="Filtrar" (click)="getLog()" icon="pi pi-filter" class="filter-button" iconPos="left"></p-button>
    <!-- Limpar Filtros -->
    <p-button label="Limpar Filtros" icon="pi pi-filter-slash" class="filter-button blue ms-3" iconPos="left">
    </p-button>
    <!-- Adicionar Usuário -->
    <p-button label="Exportar (.csv)" class="add-button ms-auto"></p-button>
  </div>

  <div class="w-100">
    <ng-container *ngFor="let item of data">
      <div class="agente-header" (click)="showTable = !showTable">
        <span class="ms-4">Nome do Agente - {{item.agente}}</span>
        <span class="mx-4">Documento - {{item.documento}}</span>
        <span>Identificação - {{item.id}}</span>
        <div class="py-4">
          <p-table *ngIf="showTable" tableStyleClass="tabela-agentes" [value]="item.consultas" [paginator]="true" [rows]="10"
            [rowsPerPageOptions]="[10,20,50]" [responsive]="true" [scrollable]="true" scrollHeight="500px"
            [scrollable]="true" scrollWidth="100%">
            <ng-template pTemplate="header">
              <tr>
                <th>Data - Hora</th>
                <th>Placa</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{rowData.data}}</td>
                <td>{{rowData.placa}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-container>
  </div>
</section>
