import { Component, OnInit } from '@angular/core';
import { ConsultaPlacasService } from 'src/app/service/consulta-placas.service';

@Component({
  selector: 'app-consulta-de-placas',
  templateUrl: './consulta-de-placas.component.html',
  styleUrls: ['./consulta-de-placas.component.scss']
})
export class ConsultaDePlacasComponent implements OnInit {
  agente: any;
  placa: any;
  dataFim: Date = new Date();
  dataInicio: Date = new Date();
  showTable: boolean = false;
  data: any[] = [];
  agentList: any[] = [];
  placaList: any[] = [];

  constructor(private consulta_placas: ConsultaPlacasService) { }

  ngOnInit(): void {
    this.getLog();
    this.getAgentes();
    this.getPlacas();
  }

  getLog(){
    this.data = [];
    const filter = {
      placa: this.placa,
      dataInicial: this.dataInicio,
      dataFinal: this.dataFim,
      agente: this.agente
    }
    console.log("🚀 ~ file: consulta-de-placas.component.ts ~ line 30 ~ ConsultaDePlacasComponent ~ getLog ~ filter", filter)
    this.consulta_placas.getLog(filter).then((data: any) => {
      this.data = data;
    });
  }

  getAgentes(){
    this.consulta_placas.getAgentes().then((data: any) => {
      this.agentList = data;
    });
  }

  getPlacas(){
    this.consulta_placas.getPlacas().then((data: any) => {
      this.placaList = data;
      console.log("🚀 ~ file: consulta-de-placas.component.ts ~ line 50 ~ ConsultaDePlacasComponent ~ this.consulta_placas.getPlacas ~ this.placaList", this.placaList)
    });
  }

}
