import { Component } from '@angular/core';

@Component({
  selector: 'app-veiculos-oficiais',
  templateUrl: './veiculos-oficiais.component.html',
  styleUrls: ['./veiculos-oficiais.component.css']
})
export class VeiculosOficiaisComponent {

}
