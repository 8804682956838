<section class="position-relative">
  <div class="page-title">
    <h5 class="fw-bold">Cidades</h5>
  </div>
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">
    <div class="filter-div p-3 pb-0 d-flex">
      <!-- Adicionar Usuário -->
      <p-button (onClick)="addUserDiag = true" label="Adicionar Cidade" icon="pi pi-plus-circle"
        class="add-button ms-auto" iconPos="left"></p-button>
    </div>
    <app-generic-table *ngIf="cityData.length > 0; else empty"
      [item]="{colunas: colunas, data: cityData, type: 1, config: {disableSort: false}}" class="p-5 pb-0"
      (selectedData)="updateCity($event.id, {status: $event.data ? 1 : 0})"></app-generic-table>
  </div>
</section>

<p-dialog header="Adicionar Usuário" [(visible)]="addUserDiag">
  <form class="addUserForm" action="" [formGroup]="addCityForm">
    <div class="row">
      <div class="col">
        <label class="form-label d-block">Estado</label>
        <input placeholder="Estado" formControlName="estado" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">Horarios Funcionamento</label>
        <p-multiSelect class="mb-3" [options]="dias" formControlName="horariosFuncionamento" optionValue="name"
          [showHeader]="false" optionLabel="name"></p-multiSelect>
        <div *ngFor="let item of addCityForm.value.horariosFuncionamento; let i = index">
          <label class="d-block" for="{{'timer'+ i}}">{{item}}</label>
          <div class="d-flex mb-3">
            <p-calendar class="me-3" [id]="'timer'+i" [showTime]="true" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="timeDateInicio[i]" appendTo="body" placeholder="Hora início" [timeOnly]="true"
              hourFormat="24"></p-calendar>
            <p-calendar [id]="'timer'+i" [showTime]="true" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="timeDateFim[i]" appendTo="body" placeholder="Hora Fim" [timeOnly]="true" hourFormat="24">
            </p-calendar>
          </div>
        </div>
        <!-- <p-button (click)="putTime()" label="Salvar" icon="pi pi-plus-circle"
        class="add-button float-end" iconPos="left"></p-button> -->
      </div>
      <div class="col">
        <label class="form-label d-block">Cidade</label>
        <input placeholder="Cidade" formControlName="nome" type="text" pInputText />
      </div>
    </div>
    <div class="row my-3">
      <div class="col">
        <label class="form-label d-block">Período Mínimo</label>
        <input placeholder="Período Mínimo" formControlName="periodoMinimo" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">Valor Hora Carro</label>
        <input placeholder="Valor Hora Carro" formControlName="valorHoraCarro" type="text" pInputText />
      </div>
      <div class="col">
        <label class="form-label d-block">Valor Hora Moto</label>
        <input placeholder="Valor Hora Moto" formControlName="valorHoraMoto" type="text" pInputText />
      </div>
    </div>
  </form>
  <p-button (click)="addCity()" [disabled]="addCityForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button>
</p-dialog>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>