import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toReal'
})
export class ToRealPipe implements PipeTransform {

  transform(value: any): unknown {
    if(value == null || value == undefined) return 'R$ 0,00';

    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedValue;
  }

}
