import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultasService {
  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getMultas(agente?: any, placa?: any, page?: number, perPage?: number, inicio: string = '', fim: string = '') {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/multas`
    if (localStorage.getItem('idCidade')) {
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if (placa) {
      link += `&placa=${placa}`
    }
    if (agente) {
      link += `&agente=${agente}`
    }
    if (page) {
      link += `&page=${page}`,
        link += `&perPage=${perPage}`
    }
    if (inicio) {
      link += `&inicio=${inicio}`
      link += `&final=${fim}`
    }

    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getVeiculosPrefeitura() {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/veiculoPrefeitura`
    if (localStorage.getItem('idCidade')) {
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  postVeiculoPrefeitura(veiculo: any) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/veiculoPrefeitura`
    if (localStorage.getItem('idCidade')) {
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .post(link, veiculo, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  deleteVeiculoPrefeitura(id: string) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/veiculoPrefeitura/${id}`
    return new Promise(async (resolve, reject) => {
      this.http
        .delete(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }
}
