<section class="loginSection">
  <div class="loginContainer position-relative">
    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="loginForm p-lg-5 text-center">
        <img src="assets/images/logo_login.svg" width="100%" alt="" class="logo">
        <form [formGroup]="loginForm" class="text-start py-5">
          <div class="mb-3" *ngIf="!passReset">
            <label for="email" class="form-label">Usuário</label>
            <input type="email" formControlName="username" class="form-control" id="email">
            <div *ngIf="!loginForm.get('email')?.valid && loginForm.get('email')?.touched" class="invalid-feedback"
              style="display: block !important;">
              Usuário inválido.
            </div>
          </div>
          <div class="mb-3 position-relative" *ngIf="!passReset">
            <label for="exampleInputPassword1" class="form-label">Senha</label>
            <input class="d-block w-100" formControlName="password" type="password" id="inputPassword"
              [feedback]="false" pPassword />
            <span class="icon-password" (click)="showHidePassword()">
              <i [ngClass]="showpassword ? 'pi pi-eye' : 'pi pi-eye-slash' " class="pi pi-eye-slash"></i>
            </span>
          </div>
          <div class="mb-3" *ngIf="passReset && !newSenha">
            <label for="Telefone" class="form-label">Usuário</label>
            <input type="text" class="form-control" [(ngModel)]="username" [ngModelOptions]="{standalone: true}"
              id="username">
          </div>
          <div class="mb-3" *ngIf="newSenha">
            <label for="Telefone" class="form-label">Codigo SMS</label>
            <input type="text" class="form-control" [(ngModel)]="OTP" [ngModelOptions]="{standalone: true}"
              id="telefone">
          </div>
          <div class="mb-3" *ngIf="newSenha">
            <label class="form-label block">Senha</label>
            <p-password [(ngModel)]="newPass" [style]="{'width': '100%'}" [ngModelOptions]="{standalone: true}" [feedback]="false" [toggleMask]="true"></p-password>
          </div>
          <div class="mb-3" *ngIf="newSenha">
            <label class="form-label block">Confirmar Senha</label>
            <p-password [(ngModel)]="confirmNum" [style]="{'width': '100%'}" [toggleMask]="true" [ngModelOptions]="{standalone: true}" [feedback]="false"></p-password>
          </div>
        </form>
        <div class="d-flex w-100 justify-content-between position-relative" *ngIf="!passReset">
          <a class="align-self-center cursor-pointer" (click)="passReset = true">Esqueci minha senha</a>
          <button class="btn btn-primary" type="button submit" [disabled]="!loginForm.valid"
            (click)="doLogin()">Entrar</button>
        </div>
        <div class="d-flex w-100 justify-content-center position-relative gap-3" *ngIf="passReset && !newSenha">
          <button class="btn btn-primary" type="button submit" (click)="passReset = false">Voltar</button>
          <button class="btn btn-primary" type="button submit" (click)="sendNum()">Solicitar código via
            sms
          </button>
        </div>
        <div class="d-flex w-100 justify-content-center position-relative gap-3" *ngIf="newSenha">
          <button class="btn btn-primary" type="button submit" (click)="passReset = false; newSenha = false">Voltar</button>
          <button class="btn btn-primary" (click)="resetPassword()" [disabled]="newPass != confirmNum || !OTP" type="button submit">Resetar Senha
          </button>
        </div>
      </div>
    </div>
  </div>
</section>