import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/loading/loading.service.component';
import { MessageService } from 'primeng/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private loading: LoadingService, private message: MessageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.loading.hideLoading();
          this.message.clear();
          if(req.url.includes('v1/admin/login')){

          }
          else{
            this.message.add({ severity: 'error', summary: 'Erro ao carregar', detail: 'Sessão expirada' });
          }
          // Remover informações de autenticação do localStorage
          localStorage.removeItem('authData');

          // Redirecionar para a rota '/login'
          this.router.navigate(['/login']);
        }

        return throwError(error);
      })
    );
  }
}
