import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class CidadeService {
  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getCidades() {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/cidade/web`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  updateCidades(id: string, data: any) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .put(`${this.base_url}/v1/cidade/${id}`, data, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  createCidade(data: any) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${this.base_url}/v1/cidade`, data, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }
}
