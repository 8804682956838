import { ChangeDetectorRef, Component } from '@angular/core';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from '@angular/router';

export const ROUTES: any[] = [
  { path: '/sistema/operacoes', title: 'Operações' },
  { path: '/sistema/usuarios/sistema', title: 'Usuários do Sistema' },
  { path: '/sistema/usuarios/monitores', title: 'Monitores' },
  { path: '/sistema/usuarios/fisc', title: 'Agente de Fiscalização' },
  { path: '/sistema/usuarios/venda', title: 'Ponto de Vendas' },
  { path: '/sistema/usuarios/clientes', title: 'Clientes' },
  { path: '/sistema/cidades', title: 'Cidades' },
  { path: '/consultas/creditos', title: 'Créditos' },
  { path: '/consultas/infracoes', title: 'Infrações' },
  { path: '/fechamento/monitores', title: 'Fechamento Monitores' },
  { path: '/fechamento/pdv', title: 'Fechamento PDV' },
  { path: '/relatorio-financeiro', title: 'Relatório Financeiro' },
  { path: '/historicos/estacionamentos', title: 'Histórico de Estacionamentos' },
  { path: '/historicos/vendas', title: 'Histórico de Vendas' },
  { path: '/historicos/fiscalizacao', title: 'Histórico de Fiscalização' },
  { path: '/sistema/dashboard', title: 'Dashboard' },
  { path: '/sistema/veiculos-autorizados', title: 'Veículos Autorizados' },
  { path: '/consultas/avaliacao-monitores', title: 'Avaliação de Monitores' },
]
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  nameScreen: string = "";

  location: Location;

  userType: string = localStorage.getItem("tipoUsuario") as string;

  constructor(
    location: Location,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
    this.location = location;
    this.getNameScreen();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getNameScreen();
      }
    });
  }

  getNameScreen() {
    var url = this.location.prepareExternalUrl(this.location.path());
    for (let i = 0; i < ROUTES.length; i++) {
      const e = ROUTES[i];
      if (url.indexOf(e.path) != -1) {
        this.nameScreen = ROUTES.filter(menuItem => menuItem)[i].title;
      }
    }
  }
}
